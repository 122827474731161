.main-footer {
    position: fixed;
    bottom: 0px;
    width: 100%;
}

button {
    margin-left: 5px;
}

.table-actions {
    width: 150px;
}

.table-hover tbody tr:hover {
    color: #212529;
    background-color: rgb(188, 199, 235);
}

.menu-portal-item:hover {
    background-color: #3762b0 !important;
    transform: scale(1.02);
}
  